import { createAsyncThunk } from '@reduxjs/toolkit';

import { getInitialData } from '../components/Profile/controller-utils';
import { ThunkApiConfig } from '../types';
import { RootState } from './root-reducer';

export const initialStateRefetch = createAsyncThunk<
  RootState,
  void,
  ThunkApiConfig
>(
  'root/initialStateRefetch',
  async (_, { extra: { flowAPI, widgetPluginService } }) => {
    const { initialState } = await getInitialData(flowAPI, widgetPluginService);
    return initialState;
  },
);
